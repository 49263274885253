import { Injectable } from '@angular/core';
import {
  State, Action, StateContext, Selector,
} from '@ngxs/store';
import { Role } from '@core/interfaces/user-role.interface';
import { SetUserRoles } from '@core/store/action/user-roles.action';

export class UserRolesStateModel {
  public roles!: Role[];
}

@State<UserRolesStateModel>({
  name: 'UserRolesState',
  defaults: {
    roles: [],
  },
})
@Injectable()
export class UserRolesState {
  constructor() {}

  @Selector()
  public static getUserRoles(state: UserRolesStateModel): Role[] {
    return state.roles;
  }

  @Action(SetUserRoles)
  public setUserRoles(ctx: StateContext<UserRolesStateModel>, { userRoles }: SetUserRoles): void {
    ctx.patchState({ roles: userRoles });
  }
}
