import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import { RoleRight } from '@core/interfaces/admin-rights.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';
import { defaultMaxPagination } from '@core/constants/reference-page-constant';
import { SearchFilter } from '@core/interfaces/search.interface';
import { PaginatedResponse } from '@core/interfaces/paginated-response.interface';
import { SetUserRights } from '@core/store/action/user.rights.action';
import { Role } from '@core/interfaces/user-role.interface';
import { SetUserRoles } from '@core/store/action/user-roles.action';

@Injectable({
  providedIn: 'root',
})
export class UserRightsService {
  constructor(
    private readonly apollo: Apollo,
    private store: Store,
  ) {}

  public getUserRights(
    filter: SearchFilter = {},
    pagination: OffsetPagination = defaultMaxPagination,
  ): Observable<RoleRight[]> {
    return this.apollo
      .query<{ getListRoleRight: PaginatedResponse<RoleRight> }>({
        query: gql`
          query getListRoleRight($filter: RoleRightsFilterGqlInput, $pagination: OffsetPaginationInput!) {
            getListRoleRight(filter: $filter, pagination: $pagination) {
              result {
                isCreate
                isDelete
                isRead
                isUpdate
                serviceName
              }
            }
          }
        `,
        variables: {
          filter,
          pagination,
        },
        fetchPolicy: FetchPolicy.NoCache,
      })
      .pipe(
        map((res) => res.data.getListRoleRight.result),
        tap((res) => this.store.dispatch(new SetUserRights(res))),
      );
  }

  public getRoles(): Observable<Role[]> {
    return this.apollo
      .query<{ roles: Role[] }>({
        query: gql`
          query roles {
            roles {
              id
              name
              roleRights {
                isCreate
                isDelete
                isRead
                isUpdate
                serviceName
              }
              type
            }
          }
        `,
        fetchPolicy: FetchPolicy.NoCache,
      })
      .pipe(
        map((res) => res.data.roles),
        tap((res) => this.store.dispatch(new SetUserRoles(res))),
      );
  }
}
